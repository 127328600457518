import { Avatar, Stack, SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { ReactComponent as bellIcon } from "images/bell.svg";
import { ZText } from "components/atoms/Text";
import { ReactComponent as pointerIcon } from "images/pointer.svg";

interface ZProfileAvatarProps {}

export const ZProfileAvatar: FC<ZProfileAvatarProps> = ({ ...rest }) => {
  return (
    <Stack {...rest}>
      <Stack
        sx={{
          height: 50,
          width: 150,
          bgcolor: "#CFDBDE",
          borderRadius: "32px",
          p: 2,
          cursor: "pointer",
        }}
        alignItems="center"
      >
        <SvgIcon component={bellIcon} inheritViewBox />
      </Stack>
      <Stack
        sx={{
          height: 50,
          width: 200,
          bgcolor: "secondary.main",
          borderRadius: "32px",
          ml: -12,
          p: 1,
          cursor: "pointer",
        }}
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={1} alignItems="center">
          <Avatar
            sx={{ width: 40, height: 40 }}
            src="https://picsum.photos/200"
          />
          <Stack direction={"column"}>
            <ZText color={"primary"} bold="bold">
              {"عبدالرحمن"}
            </ZText>
            <ZText type="description">{"أدمن"}</ZText>
          </Stack>
        </Stack>

        <SvgIcon component={pointerIcon} inheritViewBox />
      </Stack>
    </Stack>
  );
};
