import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { RequestStatus } from "constants/api";
import { CategoryItemsCount } from "constants/page";
import { Category } from "core/types/category";
import { ZMeta } from "core/types/request";
import { activeAlert } from "utils/helpers/alert";
import { ServerApi } from "utils/helpers/get-server-api";
import type { RootState } from "../store";
import { store } from "../store";

// import { setPermetion, SetRulesByType } from "./Setting";

// Define a type for the slice state

interface CategoryState {
  status: RequestStatus;
  category: Category | null;
  categories: Category[];
  meta: ZMeta | null;
}

// Define the initial state using that type
const initialState: CategoryState = {
  category: null,
  categories: [],
  status: "none",
  meta: null,
};

export const CategorySlice = createSlice({
  name: "Category",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStatus: (
      state: CategoryState,
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.status = payload;
    },
    setCategory: (state: CategoryState, { payload }: PayloadAction<any>) => {
      state.category = payload;
    },
    setCategories: (
      state: CategoryState,
      { payload }: PayloadAction<{ categories: any; meta: ZMeta }>
    ) => {
      state.categories = payload.categories;
      state.meta = payload.meta;
    },
    InsertCategory: (
      state: CategoryState,
      { payload }: PayloadAction<Category>
    ) => {
      state.categories.push(payload);
    },
    UpdateCategory: (
      state: CategoryState,
      { payload }: PayloadAction<Category>
    ) => {
      let index = state.categories.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        let categories = state.categories;
        categories[index] = payload;
        state.categories = categories;
      }
    },
    DeleteCategory: (
      state: CategoryState,
      { payload }: PayloadAction<string>
    ) => {
      const newCategories = state.categories.filter(
        (item: Category) => item.id !== payload
      );
      state.categories = newCategories;
    },
  },
});

export const {
  setStatus,
  setCategory,
  setCategories,
  InsertCategory,
  UpdateCategory,
  DeleteCategory,
} = CategorySlice.actions;

export const fetchCategories =
  (page: number = 1): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      const res = await axios.get(`${ServerApi}/categories`, {
        params: { items_per_page: CategoryItemsCount, page },
      });
      dispatch(setStatus("success"));
      dispatch(
        setCategories({ categories: res.data.data, meta: res.data.meta })
      );
    } catch (error) {
      console.log(error);
      dispatch(setStatus("error"));
    }
  };

export const storeCategory =
  ({ name }: Category): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      var form = new FormData();
      form.append("name_ar", name);
      form.append("name_en", name);
      const { data } = await axios.post(`${ServerApi}/categories`, form);
      dispatch(setStatus("success"));
      if (store.getState().Category.categories.length === CategoryItemsCount) {
        dispatch(
          fetchCategories(
            (store.getState().Category.meta?.currentPage ?? 0) + 1
          )
        );
      } else dispatch(InsertCategory(data.data));
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export const updateCategory =
  ({ name, id }: Category): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      var form = new FormData();
      form.append("name_ar", name);
      form.append("name_en", name);
      form.append("_method", "PUT");

      const { data } = await axios.post(`${ServerApi}/categories/${id}`, form);
      dispatch(setStatus("success"));
      dispatch(UpdateCategory(data.data));
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export const deleteCategory =
  (id: string): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      const { data } = await axios.delete(`${ServerApi}/categories/${id}`);
      dispatch(setStatus("success"));
      dispatch(DeleteCategory(id));
      dispatch(
        fetchCategories(store.getState().Category.meta?.currentPage ?? 1)
      );
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export default CategorySlice.reducer;
