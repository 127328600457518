import { SxProps } from "@mui/material";

interface ZTextStylesProps {
  default: SxProps;
  title: SxProps;
  subtitle: SxProps;
  // bold: SxProps;
}

export const ZTextStyles: (props: any) => ZTextStylesProps = ({
  pointer,
  bold,
}) => ({
  default: {
    ...(pointer && { cursor: "pointer" }),
  },
  title: {
    color: "primary.main",
    fontSize: "25px",
    fontWeight: "500",
  },
  subtitle: {
    color: "primary.main",
    fontSize: "20px",
    fontWeight: "bold",
  },
  description: {
    fontSize: "18px",
    color: "#3D4850",
  },
  error: {
    fontSize: "12px",
    color: "error.main",
    margin: "0 0  0 40px",
  },
  bold: {
    fontWeight: bold,
  },
});
