// import { Container } from "@mui/material"
import { FC } from "react";
import { Box } from "@mui/material";

interface AuthTemplateProps {}

export const AuthTemplate: FC<AuthTemplateProps> = ({ children }) => {
  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      <Box
        sx={{
          bgcolor: "#EFEFEF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
