import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { RequestStatus } from "constants/api";
import { activeAlert } from "utils/helpers/alert";
import { ServerApi } from "utils/helpers/get-server-api";
import type { RootState } from "../store";
// import { setPermetion, SetRulesByType } from "./Setting";

// Define a type for the slice state
export interface RegisterRequest {
  middle_name: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  phone_number: string;
}
export interface SignInRequest {
  password: string;
  phone_number: string;
}

interface AuthState {
  registerStatus: RequestStatus;
  otpStatus: RequestStatus;
  otpResendStatus: RequestStatus;

  signinStatus: RequestStatus;
  user: any;
}

// Define the initial state using that type
const initialState: AuthState = {
  registerStatus: "none",
  otpStatus: "none",
  signinStatus: "none",
  otpResendStatus: "none",
  user: null,
};

export const AuthSlice = createSlice({
  name: "Auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setRegisterStatus: (
      state: { registerStatus: string },
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.registerStatus = payload;
    },
    setOtpStatus: (
      state: { otpStatus: string },
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.otpStatus = payload;
    },
    setSigninStatus: (
      state: { signinStatus: string },
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.signinStatus = payload;
    },
    setOtpResendStatus: (
      state: { otpResendStatus: string },
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.otpResendStatus = payload;
    },
    setUser: (state: { user: any }, { payload }: PayloadAction<any>) => {
      state.user = payload;
    },
  },
});

export const {
  setRegisterStatus,
  setUser,
  setOtpStatus,
  setSigninStatus,
  setOtpResendStatus,
} = AuthSlice.actions;

export const registerAccount =
  (req: RegisterRequest): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setRegisterStatus("loading"));
    axios({
      method: "post",
      url: `${ServerApi}/register`,
      data: req,
    })
      .then(({ data }: AxiosResponse) => {
        localStorage.setItem("user", JSON.stringify(data.data));
        activeAlert({ message: data.message, severity: "success" });
        dispatch(setRegisterStatus("success"));
      })
      .catch((reason: AxiosError) => {
        activeAlert({
          message: reason.response
            ? reason.response.data.message
            : "Verify Error",
          severity: "error",
        });
        dispatch(setRegisterStatus("error"));
      });
  };

export const VerifyCode =
  (code: string): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setOtpStatus("loading"));
    const phone = JSON.parse(localStorage.getItem("user") ?? "")?.phone_number;
    if (!phone) {
      throw new Error("Can't find phone number");
    }
    axios({
      method: "post",
      url: `${ServerApi}/verify-phone-number`,
      data: {
        otp: code,
        phone_number: phone,
      },
    })
      .then(({ data: { token, message } }: AxiosResponse) => {
        let user = JSON.parse(localStorage.getItem("user") ?? "");
        user.is_phone_number_verify = true;
        localStorage.setItem("user", JSON.stringify({ token, ...user }));
        axios.interceptors.request.use(function (config: any) {
          config.headers.Authorization = `Bearer ${token}`;
          config.headers["Accept-Language"] = "ar";
          return config;
        });
        // dispatch(SetRulesByType(user.user_type.type));
        activeAlert({ message: message, severity: "success" });
        dispatch(setOtpStatus("success"));
        window.location.reload();
      })
      .catch((reason: AxiosError) => {
        activeAlert({
          message: reason.response
            ? reason.response.data.message
            : "Verify Error",
          severity: "error",
        });
        dispatch(setOtpStatus("error"));
      });
  };
export const ResendCode =
  (): ThunkAction<void, RootState, unknown, any> => async (dispatch) => {
    dispatch(setOtpResendStatus("loading"));
    const phone = JSON.parse(localStorage.getItem("user") ?? "")?.phone_number;
    if (!phone) {
      throw new Error("Can't find phone number");
    }
    axios({
      method: "post",
      url: `${ServerApi}/send-otp-again`,
      data: {
        phone_number: phone,
      },
    })
      .then(({ data }) => {
        activeAlert({ message: data.message, severity: "success" });
        dispatch(setOtpResendStatus("success"));
      })
      .catch((reason: any) => {
        activeAlert({
          message: reason.response
            ? reason.response.data.message
            : "Resend code Error",
          severity: "error",
        });
        dispatch(setOtpResendStatus("error"));
      });
  };

export const signinAccount =
  (req: SignInRequest): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setSigninStatus("loading"));
    // try {
    axios({
      method: "post",
      url: `${ServerApi}/login`,
      data: req,
    })
      .then(({ data }: AxiosResponse) => {
        // Handle response
        localStorage.setItem(
          "user",
          JSON.stringify({ token: data.token, ...data.data })
        );
        axios.interceptors.request.use(function (config: any) {
          config.headers.Authorization = `Bearer ${data.token}`;
          config.headers["Accept-Language"] = "ar";
          return config;
        });
        activeAlert({ message: data.message, severity: "success" });
        dispatch(setSigninStatus("success"));
      })
      .catch((reason: AxiosError) => {
        activeAlert({
          message: reason.response
            ? reason.response.data.message
            : "Login Error",
          severity: "error",
        });
        dispatch(setSigninStatus("error"));
      });

    // } catch (err: any) {
    //   console.log(err, "second error");

    //   activeAlert({
    //     message: "Signin error",
    //     severity: "error",
    //   });
    //   dispatch(setSigninStatus("error"));
    // }
  };
export const LogoutAccount =
  (): ThunkAction<void, RootState, unknown, any> => async (dispatch) => {
    dispatch(setSigninStatus("loading"));
    axios({
      method: "delete",
      url: `${ServerApi}/logout`,
    })
      .then(({ data }) => {
        localStorage.removeItem("user");
        axios.interceptors.request.use(function (config: any) {
          config.headers.Authorization = ``;
          return config;
        });
        activeAlert({ message: data.message, severity: "success" });
        dispatch(setSigninStatus("success"));
        window.location.reload();
      })
      .catch((reason: AxiosError) => {
        activeAlert({
          message: reason.response
            ? reason.response.data.message
            : "Logout Error",
          severity: "error",
        });
        dispatch(setSigninStatus("error"));
      });
  };
export default AuthSlice.reducer;
