import { SxProps } from "@mui/material";

interface ZInputStylesProps {
  default: SxProps;
  disableUnderline: SxProps;
}

export const ZInputStyles: (props: any) => ZInputStylesProps = ({}) => ({
  default: {
    p: "0px",
    width: "100%",
    height: "52px",
  },
  disableUnderline: {
    p: "0px",
  },
});
