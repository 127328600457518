import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { RequestStatus } from "constants/api";
import { FeatureItemsCount } from "constants/page";
import { Feature, FeatureValues } from "core/types/feature";
import { ZMeta } from "core/types/request";
import qs from "qs";
import { activeAlert } from "utils/helpers/alert";
import { ServerApi } from "utils/helpers/get-server-api";
import type { RootState } from "../store";
import { store } from "../store";

// import { setPermetion, SetRulesByType } from "./Setting";

// Define a type for the slice state

interface FeatureState {
  colorStatus: RequestStatus;
  colors: Feature | null;
  colorMeta: ZMeta | null;
  sizeStatus: RequestStatus;
  sizes: Feature | null;
  sizeMeta: ZMeta | null;

  fabricStatus: RequestStatus;
  fabrics: Feature | null;
  fabricMeta: ZMeta | null;
}

// Define the initial state using that type
const initialState: FeatureState = {
  colors: null,
  colorStatus: "none",
  colorMeta: null,
  sizes: null,
  sizeStatus: "none",
  sizeMeta: null,
  fabricStatus: "none",
  fabrics: null,
  fabricMeta: null,
};

export const FeatureSlice = createSlice({
  name: "Feature",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStatus: (
      state: FeatureState,
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.colorStatus = payload;
    },
    setFeature: (state: FeatureState, { payload }: PayloadAction<any>) => {
      state.colorStatus = payload;
    },
    setFeatures: (
      state: FeatureState,
      { payload }: PayloadAction<{ features: any; meta: ZMeta }>
    ) => {
      state.colors = payload.features;
      state.colorMeta = payload.meta;
    },
    InsertColor: (
      state: FeatureState,
      { payload }: PayloadAction<FeatureValues>
    ) => {
      state.colors?.values.push(payload);
    },
    setSizesStatus: (
      state: FeatureState,
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.sizeStatus = payload;
    },

    setSizes: (
      state: FeatureState,
      { payload }: PayloadAction<{ features: any; meta: ZMeta }>
    ) => {
      state.sizes = payload.features;
      state.sizeMeta = payload.meta;
    },
    setFabricStatus: (
      state: FeatureState,
      { payload }: PayloadAction<RequestStatus>
    ) => {
      state.fabricStatus = payload;
    },

    setFabrics: (
      state: FeatureState,
      { payload }: PayloadAction<{ features: any; meta: ZMeta }>
    ) => {
      state.fabrics = payload.features;
      state.fabricMeta = payload.meta;
    },

    // UpdateFeature: (
    //   state: FeatureState,
    //   { payload }: PayloadAction<FeatureValues>
    // ) => {
    //   let index = state.colors?.values.findIndex(
    //     (item) => item.id === payload.id
    //   );
    //   if (index !== -1) {
    //     let features = state.colors?.values;
    //     if (features !== undefined) {
    //       features[index] = payload;
    //       state.colors?.values = features;
    //     }
    //   }
    // },
    // DeleteFeature: (
    //   state: FeatureState,
    //   { payload }: PayloadAction<number>
    // ) => {
    //   const newFeatures = state.features.filter(
    //     (item: Feature) => item.id !== payload
    //   );
    //   state.features = newFeatures;
    // },
  },
});

export const {
  setStatus,
  setFeature,
  setFeatures,
  InsertColor,
  setSizes,
  setSizesStatus,
  setFabricStatus,
  setFabrics,
  // UpdateFeature,
  // DeleteFeature,
} = FeatureSlice.actions;

export const fetchColors =
  (page: number = 1): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      const res = await axios.get(`${ServerApi}/features`, {
        params: {
          items_per_page: FeatureItemsCount,
          page,
          "filter[key]": "Color",
        },
      });
      dispatch(setStatus("success"));
      dispatch(
        setFeatures({ features: res.data.data[0], meta: res.data.meta })
      );
    } catch (error) {
      console.log(error);
      dispatch(setStatus("error"));
    }
  };

export const fetchSizes =
  (page: number = 1): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setSizesStatus("loading"));
    try {
      const res = await axios.get(`${ServerApi}/features`, {
        params: {
          items_per_page: FeatureItemsCount,
          page,
          "filter[key]": "Size",
        },
      });
      dispatch(setSizesStatus("success"));
      dispatch(setSizes({ features: res.data.data[0], meta: res.data.meta }));
    } catch (error) {
      console.log(error);
      dispatch(setSizesStatus("error"));
    }
  };
export const storeColor =
  (
    featureId: number,
    feature: {
      value: string;
      category_id?: number;
    }
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.post(
        `${ServerApi}/features/${featureId}/feature-values`,
        qs.stringify({
          "feature_values[]": JSON.stringify(feature),
        })
      );
      dispatch(setStatus("success"));
      dispatch(fetchColors());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };
export const editColor =
  (
    featureValueId: number,
    featureId: number,

    feature: {
      value: string;
      category_id?: number;
    }
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.put(
        `${ServerApi}/features/${featureId}/feature-values/${featureValueId}`,
        qs.stringify({
          "feature_values[]": JSON.stringify(feature),
        })
      );
      dispatch(setStatus("success"));
      dispatch(fetchColors());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };
export const deleteColor =
  (
    featureValueId: number,
    featureId: number
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.delete(
        `${ServerApi}/features/${featureId}/feature-values/${featureValueId}`
      );
      dispatch(setStatus("success"));
      dispatch(fetchColors());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export const storeSize =
  (
    featureId: number,
    feature: {
      value: string;
      category_id?: number;
    }
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setSizesStatus("loading"));
      const { data } = await axios.post(
        `${ServerApi}/features/${featureId}/feature-values`,
        qs.stringify({
          "feature_values[]": JSON.stringify(feature),
        })
      );
      dispatch(setSizesStatus("success"));
      dispatch(fetchSizes());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setSizesStatus("error"));
    }
  };

export const editSize =
  (
    featureValueId: number,
    featureId: number,
    feature: {
      value: string;
      category_id?: number;
    }
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setSizesStatus("loading"));
      const { data } = await axios.put(
        `${ServerApi}/features/${featureId}/feature-values/${featureValueId}`,
        qs.stringify({
          "feature_values[]": JSON.stringify(feature),
        })
      );
      dispatch(setSizesStatus("success"));
      dispatch(fetchSizes());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setSizesStatus("error"));
    }
  };

export const deleteSize =
  (
    featureValueId: number,
    featureId: number
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setSizesStatus("loading"));
      const res = await axios.delete(
        `${ServerApi}/features/${featureId}/feature-values/${featureValueId}`
      );
      dispatch(setSizesStatus("success"));
      dispatch(fetchSizes());
      activeAlert({
        message: res.data.message,
        severity: "success",
      });
    } catch (error: any) {
      activeAlert({
        message: error.message,
        severity: "success",
      });
      dispatch(setSizesStatus("error"));
    }
  };

export const fetchFabrics =
  (page: number = 1): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch(setFabricStatus("loading"));
    try {
      const res = await axios.get(`${ServerApi}/features`, {
        params: {
          items_per_page: FeatureItemsCount,
          page,
          "filter[key]": "Fabric",
        },
      });
      dispatch(setFabricStatus("success"));
      dispatch(setFabrics({ features: res.data.data[0], meta: res.data.meta }));
    } catch (error) {
      console.log(error);
      dispatch(setFabricStatus("error"));
    }
  };

export const storeFabric =
  (
    featureId: number,
    feature: {
      value: string;
      category_id?: number;
    }
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setFabricStatus("loading"));
      const { data } = await axios.post(
        `${ServerApi}/features/${featureId}/feature-values`,
        qs.stringify({
          "feature_values[]": JSON.stringify(feature),
        })
      );
      dispatch(setFabricStatus("success"));
      dispatch(fetchFabrics());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setFabricStatus("error"));
    }
  };
export const editFabric =
  (
    featureValueId: number,
    featureId: number,

    feature: {
      value: string;
      category_id?: number;
    }
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setFabricStatus("loading"));
      const { data } = await axios.put(
        `${ServerApi}/features/${featureId}/feature-values/${featureValueId}`,
        qs.stringify({
          "feature_values[]": JSON.stringify(feature),
        })
      );
      dispatch(setFabricStatus("success"));
      dispatch(fetchFabrics());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setFabricStatus("error"));
    }
  };
export const deleteFabric =
  (
    featureValueId: number,
    featureId: number
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setFabricStatus("loading"));
      const { data } = await axios.delete(
        `${ServerApi}/features/${featureId}/feature-values/${featureValueId}`
      );
      dispatch(setFabricStatus("success"));
      dispatch(fetchFabrics());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setFabricStatus("error"));
    }
  };

export default FeatureSlice.reducer;
