import { lazy } from "react";

// project imports
import Loadable from "components/molecules/Loadable";
import { MinimalLayout } from "containers/layout";

const AuthLogin = Loadable(lazy(() => import("pages/login")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/auth",
  element: <MinimalLayout />,
  exact: true,

  children: [
    {
      path: "/auth/login",
      element: <AuthLogin />,
      exact: true,
    },
  ],
};

export default AuthenticationRoutes;
