import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RequestStatus } from "constants/api";
import { RootState } from "core/store";
import { Wholesaler } from "core/types/wholesaler";
import { activeAlert } from "utils/helpers/alert";
import { ServerApi } from "utils/helpers/get-server-api";
import qs from "qs";

// import { setPermetion, SetRulesByType } from "./Setting";

// Define a type for the slice state

interface WholesalerState {
  status: RequestStatus;
  rolesStatus: RequestStatus;

  Wholesalers: Wholesaler[];
  roles: string[];
}

// Define the initial state using that type
const initialState: WholesalerState = {
  status: "none",
  rolesStatus: "none",
  Wholesalers: [],
  roles: [],
};

export const WholesalerSlice = createSlice({
  name: "Wholesaler",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state: any, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },
    setRoleStatus: (state: any, { payload }: PayloadAction<RequestStatus>) => {
      state.rolesStatus = payload;
    },
    setRoles: (state: any, { payload }: PayloadAction<string[]>) => {
      state.roles = payload;
    },
    setWholesalers: (state: any, { payload }: PayloadAction<Wholesaler[]>) => {
      state.Wholesalers = payload;
    },
  },
});

export const { setStatus, setWholesalers, setRoles, setRoleStatus } =
  WholesalerSlice.actions;

export default WholesalerSlice.reducer;

export const fetchWholesalers =
  (): ThunkAction<void, RootState, unknown, any> => async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      const { data } = await axios.get(`${ServerApi}/wholesalers`);
      dispatch(setWholesalers(data.data));
      dispatch(setStatus("success"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("error"));
    }
  };
