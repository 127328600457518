import React, { useEffect } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import zoomImage from "images/zoom.png";
import { Avatar, Container, ListItemAvatar, SxProps } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import { LogoutAccount, setUser } from "core/slices/Auth";
import { RootState } from "core/store";
import { useDispatch, useSelector } from "react-redux";
import { getDraweTap } from "utils/helpers/get-drawer-tap";
import { SetRulesByType, toggleSideBarIsOpen } from "core/slices/Setting";
import { ZSearchInput } from "components/molecules/SearchInput";
import { ZProfileAvatar } from "components/molecules/ProfileAvatar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(12)} + 2px)`,
  // [theme.breakpoints.up("sm")]: {
  width: `calc(${theme.spacing(12)} - 7px )`,
  // },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: "70px",

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  boxShadow: "none",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const MdDownHide = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const SmDownHide = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export function MainLayout() {
  //hooks
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permetion, sideBarIsOpen } = useSelector(
    (state: RootState) => state.Settings
  );

  //states
  // const [open, setOpen] = React.useState(false);
  const [activeTap, setActiveTap] = React.useState<number>(
    getDraweTap(
      pathname,
      permetion?.tabs ?? [],
      permetion?.admin === "admin" ? 2 : 0
    )
  );
  useEffect(() => {
    setActiveTap(
      getDraweTap(
        pathname,
        permetion?.tabs ?? [],
        permetion?.admin === "admin" ? 2 : 0
      )
    );
  }, [permetion]);

  const handelLogout = () => {
    // dispatch(LogoutAccount());
  };
  useEffect(() => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      const userJson = JSON.parse(userStr);
      axios.interceptors.request.use(function (config: any) {
        config.headers.Authorization = `Bearer ${userJson.token}`;
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Accept-Language"] = "ar";
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        return config;
      });
    } else {
      navigate("/auth/login");
    }
  }, []);
  const handleDrawer = () => {
    dispatch(toggleSideBarIsOpen());
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={sideBarIsOpen}
        sx={{
          bgcolor: "background.paper",
          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, height: "130px", width: "160px" }}>
            <img
              src={zoomImage}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <ZSearchInput />
          </Box>

          <ZProfileAvatar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={sideBarIsOpen}
        onMouseOver={handleDrawer}
        onMouseOut={handleDrawer}
        sx={{ position: "absolute" }}
      >
        <List sx={{ mx: 1 }}>
          {permetion?.tabs.map(({ link, title, Icon }, index) => (
            <ListItem
              onClick={() => {
                setActiveTap(index);
                navigate(`/${link}`);
              }}
              sx={{
                px: 0,
                ...(index === activeTap &&
                  sideBarIsOpen && {
                    bgcolor: "secondary.main",
                    borderRadius: "36px",
                  }),
                cursor: "pointer",
              }}
              key={title}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: "transparent",
                    ...(index === activeTap && {
                      bgcolor: "secondary.main",
                      borderRadius: "36px",
                    }),
                    mx: 2,
                  }}
                >
                  <Icon
                    sx={{
                      ...(index === activeTap
                        ? { color: "primary.main" }
                        : { color: "grey.500" }),
                    }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ mx: 1 }} primary={t(title)} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Container fixed sx={{ py: 3, ml: 12 }}>
        <DrawerHeader />
        {/* <ZBreadCrumbs links={[{ title: "categories", href: "/categories" }]} /> */}

        <Outlet />
      </Container>
    </Box>
  );
}
