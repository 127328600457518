import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./slices/Auth";
import SettingsReducer from "./slices/Setting";
import CategoryReducer from "./slices/Category";
import FeatureReducer from "./slices/Feature";
import UserPermissionReducer from "./slices/UserPermission";
import WholesalerReducer from "./slices/Wholesaler";
import CustomerReducer from "./slices/Customers";

export const store = configureStore({
  reducer: {
    Settings: SettingsReducer,
    Category: CategoryReducer,
    Auth: AuthReducer,
    Feature: FeatureReducer,
    UserPermission: UserPermissionReducer,
    Wholesaler: WholesalerReducer,
    Customer: CustomerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
