export const getDraweTap = (
  path: string,
  drawerLinks: any[],
  defaultValue: any = 0
) => {
  let tapIndex = defaultValue;
  drawerLinks.forEach(({ link, ...rest }, index) => {
    if (path.includes(link)) {
      tapIndex = index;
      return;
    }
  });
  return tapIndex;
};
