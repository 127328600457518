import { Box, Button, Stack, SvgIcon } from "@mui/material";
import { FC } from "react";
import { ReactComponent as filterIcon } from "images/filter.svg";
import { ZInput } from "components/atoms/Input";
import SearchIcon from "@mui/icons-material/Search";
interface ZSearchInputProps {}

export const ZSearchInput: FC<ZSearchInputProps> = ({ ...rest }) => {
  return (
    <Stack spacing={1} {...rest}>
      <ZInput
        sx={{ height: "45px", width: 445 }}
        placeholder={"ابحث هنا"}
        startAdornment={<SearchIcon sx={{ m: 2, color: "#CFDBDE" }} />}
      />
      <Button
        variant="contained"
        sx={{
          borderRadius: "15px",
          width: "45px",
          height: "45px",
          // p: 0,
          minWidth: 0,
        }}
      >
        <SvgIcon
          component={filterIcon}
          inheritViewBox
          sx={{ width: 20, height: 20 }}
        />
      </Button>
    </Stack>
  );
};
