import { Permetion } from "core/types/rules";
// import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import StyleIcon from "@mui/icons-material/Style";
// import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
export const AdminPermetion: Permetion = {
  admin: "admin",
  routes: "all",
  actions: {
    bill: "all",
    category: "all",
    feature: "all",
    package: "all",
    fair: "all",
    product: "all",
    user: "all",
    wholeSaler: "all",
  },
  //TODO: Remove Icon from here
  tabs: [
    { title: "features", Icon: StyleIcon, link: "features" },
    { title: "categories", Icon: StyleIcon, link: "categories" },
    { title: "permissions", Icon: StyleIcon, link: "permissions" },
    { title: "customers", Icon: StyleIcon, link: "customers" },
    { title: "wholesalers", Icon: StyleIcon, link: "wholesalers" },
  ],
};
