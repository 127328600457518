import { AuthTemplate } from "components/auth";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const MinimalLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userStr = localStorage.getItem("auth");
    if (userStr) {
      const user = JSON.parse(userStr);
      if (user.is_phone_number_verify) {
        navigate("/");
      }
    }
  }, []);
  return (
    <AuthTemplate>
      <Outlet />
    </AuthTemplate>
  );
};
