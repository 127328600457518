import { FC, useMemo } from "react";
import { ZTextStyles } from "components/atoms/styles";
import { propsToStyles } from "utils/helpers";
import { SxProps, Typography, TypographyProps } from "@mui/material";

export interface ZTextProps {
  type?: "title" | "subtitle" | "description" | "error";
  bold?: string;
  pointer?: boolean;
}

export const ZText: FC<ZTextProps & TypographyProps> = ({
  children,
  type,
  bold,
  sx,
  pointer,
  ...rest
}) => {
  const styles: SxProps = useMemo(() => {
    let options: any = { bold, pointer };
    switch (type) {
      case "title":
        options = { ...options, title: true };
        break;
      case "subtitle":
        options = { ...options, subtitle: true };
        break;
      case "description":
        options = { ...options, description: true };
        break;
      case "error":
        options = { ...options, error: true };
        break;
      default:
        break;
    }
    return propsToStyles(ZTextStyles, { ...options });
  }, [rest, type]);
  let allStyles = { ...styles, ...sx };
  return (
    <Typography sx={allStyles} {...rest}>
      {children}
    </Typography>
  );
};
