import { SxProps } from "@mui/material";

export const propsToStyles : (componentStylesFun: any, props: object) => SxProps = (componentStylesFun, props) =>{
  //Get keys from props
  const propsKeys : string[] = Object.keys(props);
  let styles : SxProps = {} 
  let componentStyles = componentStylesFun(props)
  //Get props styles and merge in one it object
  propsKeys.forEach((key:string) => {
      let styleGroup = componentStyles[key];
      if(styleGroup){
         styles = {
           ...styles,
           ...styleGroup
         }
      }
  });
  if(componentStyles["default"]){
    styles = {
        ...styles,
        ...componentStyles["default"]
      }
  }
  return styles;
}