import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RequestStatus } from "constants/api";
import { RootState } from "core/store";
import { Customer } from "core/types/customer";
import { activeAlert } from "utils/helpers/alert";
import { ServerApi } from "utils/helpers/get-server-api";
import qs from "qs";

// import { setPermetion, SetRulesByType } from "./Setting";

// Define a type for the slice state

interface CustomerState {
  status: RequestStatus;

  Customers: Customer[];
}

// Define the initial state using that type
const initialState: CustomerState = {
  status: "none",
  Customers: [],
};

export const CustomerSlice = createSlice({
  name: "Customer",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state: any, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },

    setCustomers: (state: any, { payload }: PayloadAction<Customer[]>) => {
      state.Customers = payload;
    },
  },
});

export const { setStatus, setCustomers } = CustomerSlice.actions;

export default CustomerSlice.reducer;

export const fetchCustomers =
  (): ThunkAction<void, RootState, unknown, any> => async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      const { data } = await axios.get(`${ServerApi}/customers`);
      dispatch(setCustomers(data.data));
      dispatch(setStatus("success"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("error"));
    }
  };

export const storeCustomer =
  ({
    locations,
    name_customer,
    numbers,
    wholesaler_id,
  }: Customer): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.post(
        `${ServerApi}/customers`,
        qs.stringify({
          name_customer,
          locations: locations?.map((loc) => loc.location) ?? [],
          numbers: numbers?.map((num) => num.number) ?? [],
          wholesaler_id,
        })
      );

      dispatch(setStatus("success"));
      dispatch(fetchCustomers());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export const editCustomer =
  ({
    locations,
    name_customer,
    numbers,
    wholesaler_id,
    id,
  }: Customer): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.put(
        `${ServerApi}/customers/${id}`,
        qs.stringify({
          name_customer,
          locations: locations?.map((loc) => loc.location) ?? [],
          numbers: numbers?.map((num) => num.number) ?? [],
          wholesaler_id,
        })
      );

      dispatch(setStatus("success"));
      dispatch(fetchCustomers());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export const deleteCustomer =
  (id: number): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.delete(`${ServerApi}/customers/${id}`);

      dispatch(setStatus("success"));
      dispatch(fetchCustomers());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

// export const editCustomer =
//   ({
//     full_name,
//     phone_number,
//     password,
//     role_name,
//     wholesalerId,
//     id,
//   }: extendCustomer): ThunkAction<void, RootState, unknown, any> =>
//   async (dispatch) => {
//     try {
//       dispatch(setStatus("loading"));
//       const { data } = await axios.post(
//         `${ServerApi}/customers/${id}`,
//         qs.stringify({
//           full_name,
//           phone_number,
//           password,
//           role_name,
//           _method: "PUT",
//         })
//       );
//       const customerData = data.data;
//       var assginData = new FormData();
//       assginData.append("role_names[]", role_name);
//       if (wholesalerId) {
//         assginData.append("wholesaler_id", wholesalerId.toString());
//       }
//       await axios.post(
//         `${ServerApi}/assign-role-to-customer/${customerData.id}`,
//         assginData
//       );
//       if (wholesalerId) {
//         var assginWholesalerData = new FormData();
//         assginWholesalerData.append("wholesaler_id", wholesalerId.toString());
//         await axios.post(
//           `${ServerApi}/assign-customer-to-wholesaler/${customerData.id}`,
//           assginWholesalerData
//         );
//       }
//       dispatch(setStatus("success"));
//       dispatch(fetchCustomers());
//       activeAlert({
//         message: data.message,
//         severity: "success",
//       });
//     } catch (error: any) {
//       console.log(error);
//       activeAlert({
//         message: error.message,
//         severity: "error",
//       });
//       dispatch(setStatus("error"));
//     }
//   };
