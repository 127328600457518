import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RequestStatus } from "constants/api";
import { RootState } from "core/store";
import { UserPermission } from "core/types/userPermission";
import { activeAlert } from "utils/helpers/alert";
import { ServerApi } from "utils/helpers/get-server-api";
import qs from "qs";
import { extendUserPermission } from "pages/permissions/UserForm";

// import { setPermetion, SetRulesByType } from "./Setting";

// Define a type for the slice state

interface UserPermissionState {
  status: RequestStatus;
  rolesStatus: RequestStatus;

  UsersPermission: UserPermission[];
  roles: string[];
}

// Define the initial state using that type
const initialState: UserPermissionState = {
  status: "none",
  rolesStatus: "none",
  UsersPermission: [],
  roles: [],
};

export const UserPermissionSlice = createSlice({
  name: "UserPermission",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state: any, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },
    setRoleStatus: (state: any, { payload }: PayloadAction<RequestStatus>) => {
      state.rolesStatus = payload;
    },
    setRoles: (state: any, { payload }: PayloadAction<string[]>) => {
      state.roles = payload;
    },
    setUsersPermission: (
      state: any,
      { payload }: PayloadAction<UserPermission[]>
    ) => {
      state.UsersPermission = payload;
    },
    changeActive: (state: any, { payload }: PayloadAction<number>) => {
      let UsersPermissionTemp = state.UsersPermission;
      UsersPermissionTemp = UsersPermissionTemp.map((user: UserPermission) => {
        if (user.id === payload) {
          return { ...user, account_activation: !user.account_activation };
        }
        return user;
      });
      state.UsersPermission = UsersPermissionTemp;
    },
  },
});

export const {
  setStatus,
  setUsersPermission,
  changeActive,
  setRoles,
  setRoleStatus,
} = UserPermissionSlice.actions;

export default UserPermissionSlice.reducer;

export const fetchUsersPermission =
  (): ThunkAction<void, RootState, unknown, any> => async (dispatch) => {
    dispatch(setStatus("loading"));
    try {
      const { data } = await axios.get(`${ServerApi}/users`);
      dispatch(setUsersPermission(data.data));
      dispatch(setStatus("success"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("error"));
    }
  };
export const toggleActiveAccount =
  (id: number): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${ServerApi}/toggleActiveAccount/${id}`
      );
      dispatch(changeActive(id));
      activeAlert({ message: data.message, severity: "success" });
    } catch (error: any) {
      console.log(error);
      activeAlert({ message: error.message, severity: "success" });
      dispatch(setStatus("error"));
    }
  };

export const fetchRoles =
  (): ThunkAction<void, RootState, unknown, any> => async (dispatch) => {
    try {
      dispatch(setRoleStatus("loading"));
      const { data } = await axios.get(`${ServerApi}/indexRole`);
      dispatch(setRoles(data.data));
      dispatch(setRoleStatus("success"));
    } catch (error) {
      console.log(error);
      dispatch(setRoleStatus("error"));
    }
  };

export const storeUser =
  ({
    full_name,
    phone_number,
    password,
    role_name,
    wholesalerId,
  }: extendUserPermission): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.post(
        `${ServerApi}/users`,
        qs.stringify({
          full_name,
          phone_number,
          password,
          role_name,
        })
      );
      const userData = data.data;
      var assginData = new FormData();
      assginData.append("role_names[]", role_name);
      if (wholesalerId) {
        assginData.append("wholesaler_id", wholesalerId.toString());
      }
      await axios.post(
        `${ServerApi}/assign-role-to-user/${userData.id}`,
        assginData
      );
      if (wholesalerId) {
        var assginWholesalerData = new FormData();
        assginWholesalerData.append("wholesaler_id", wholesalerId.toString());
        await axios.post(
          `${ServerApi}/assign-user-to-wholesaler/${userData.id}`,
          assginWholesalerData
        );
      }
      dispatch(setStatus("success"));
      dispatch(fetchUsersPermission());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };

export const editUser =
  ({
    full_name,
    phone_number,
    password,
    role_name,
    wholesalerId,
    id,
  }: extendUserPermission): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const { data } = await axios.post(
        `${ServerApi}/users/${id}`,
        qs.stringify({
          full_name,
          phone_number,
          password,
          role_name,
          _method: "PUT",
        })
      );
      const userData = data.data;
      var assginData = new FormData();
      assginData.append("role_names[]", role_name);
      if (wholesalerId) {
        assginData.append("wholesaler_id", wholesalerId.toString());
      }
      await axios.post(
        `${ServerApi}/assign-role-to-user/${userData.id}`,
        assginData
      );
      if (wholesalerId) {
        var assginWholesalerData = new FormData();
        assginWholesalerData.append("wholesaler_id", wholesalerId.toString());
        await axios.post(
          `${ServerApi}/assign-user-to-wholesaler/${userData.id}`,
          assginWholesalerData
        );
      }
      dispatch(setStatus("success"));
      dispatch(fetchUsersPermission());
      activeAlert({
        message: data.message,
        severity: "success",
      });
    } catch (error: any) {
      console.log(error);
      activeAlert({
        message: error.message,
        severity: "error",
      });
      dispatch(setStatus("error"));
    }
  };
