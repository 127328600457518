import { lazy } from "react";

// project imports
import { MainLayout } from "containers/layout";
import Loadable from "components/molecules/Loadable";

// dashboard routing

export const FeaturesPage = Loadable(lazy(() => import("pages/fearures")));
export const CategoriesPage = Loadable(lazy(() => import("pages/categories")));
export const WholesalersPage = Loadable(
  lazy(() => import("pages/wholesalers"))
);
export const PermissionsPage = Loadable(
  lazy(() => import("pages/permissions"))
);
export const CustomersPage = Loadable(lazy(() => import("pages/customers")));

const routes = [
  {
    path: "/customers",
    element: <CustomersPage />,
    expect: true,
  },
  {
    path: "/permissions",
    element: <PermissionsPage />,
    expect: true,
  },
  {
    path: "/features",
    element: <FeaturesPage />,
    expect: true,
  },
  {
    path: "/categories",
    element: <CategoriesPage />,
    expect: true,
  },
  {
    path: "/wholesalers",
    element: <WholesalersPage />,
    expect: true,
  },
  {
    path: "/",
    element: <FeaturesPage />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: routes,
};

export default MainRoutes;
