import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "theme";
import { Alert, CssBaseline, Snackbar } from "@mui/material";
import Routes from "routes";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useSelector } from "react-redux";
import { RootState } from "core/store";
import { disactiveAlert } from "utils/helpers/alert";
import { ZText } from "components/atoms/Text";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

function App() {
  const {
    alert: { message, severity, active },
  } = useSelector((state: RootState) => state.Settings);
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {/* <NavigationScroll> */}
      <CacheProvider value={cacheRtl}>
        <div dir="rtl">
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={active}
            autoHideDuration={4000}
            onClose={disactiveAlert}
          >
            <Alert
              onClose={disactiveAlert}
              severity={severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <Routes />
        </div>
      </CacheProvider>

      {/* </NavigationScroll> */}
    </ThemeProvider>
  );
}

export default App;
