import { FC, ReactElement, useMemo } from "react";
import { ZInputStyles } from "components/atoms/styles";
import { propsToStyles } from "utils/helpers";
import {
  OutlinedInput,
  SxProps,
  InputProps,
  FormControl,
  Stack,
} from "@mui/material";
import { ZText } from "./Text";

interface ZInputProps {
  Icon?: any;
  color?: "success" | "error" | "primary" | "secondary" | "info" | "warning";
  label?: string;
  onClickIcon?: () => void;
  password?: boolean;
  errorMessage?: string;
}

export const ZInput: FC<ZInputProps & InputProps> = ({
  Icon,
  onClickIcon,
  color = "success",
  disableUnderline,
  label,
  sx,
  password,
  type = "text",
  errorMessage,
  ...rest
}) => {
  const styles: SxProps = useMemo(() => {
    return propsToStyles(ZInputStyles, { disableUnderline });
  }, [rest]);
  const IconComponent: ReactElement = useMemo(() => {
    if (!Icon) return <div></div>;
    if (password)
      return (
        <Icon
          onClick={onClickIcon}
          sx={{ mr: 2, ml: 0, p: 0, color: "primary.main", cursor: "pointer" }}
        />
      );

    return <Icon sx={{ color: "text.primary", mr: 2 }} />;
  }, [password, Icon]);
  const allStyles = { ...styles, ...sx };
  return (
    <Stack direction={"column"} spacing={1}>
      {label && <ZText>{label}</ZText>}
      <FormControl variant="outlined">
        <OutlinedInput
          color={color}
          sx={allStyles}
          type={type}
          endAdornment={IconComponent}
          {...rest}
        />
      </FormControl>
      {errorMessage && <ZText type="error">{errorMessage}</ZText>}{" "}
    </Stack>
  );
};
