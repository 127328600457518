import { createTheme, experimental_sx as sx } from "@mui/material/styles";

export const lightTheme = createTheme({
  direction: "rtl",
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1000,
      lg: 1250,
      xl: 1536,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: sx({ bgcolor: "grey.400" }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: sx({ p: 1 }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: sx({
          color: "primary.main",
        }),
      },
      defaultProps: {
        sx: {
          border: "1px solid #CFDBDE99",
          borderRadius: "8px",
          bgcolor: "white",
          color: "primary.main",
          fontWeight: "bold",
          height: "45px",
          width: "180px",
          textAlign: "center",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: sx({ borderRadius: "20px" }),
      },
      defaultProps: { dir: "rtl" },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: sx({
          color: "primary.main",
          fontSize: "20px",
          fontWeight: "bold",
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: sx({
          boxShadow: "none",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: sx({
          boxShadow: "none",
        }),
      },
    },
    MuiStack: { defaultProps: { direction: "row" } },
    MuiDrawer: {
      styleOverrides: {
        paper: sx({
          borderRadius: "20px",
          ml: 2,
          pt: 2,
          mt: 11,
          height: "83%",
          boxٍhadow: "-6px 0px 10px #0000000D",
          border: "3px solid #70707005",
        }),
        root: sx({ ml: 3, mt: 11 }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: sx({
          bgcolor: "grey.400",
          borderRadius: "10px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        }),
        error: sx({
          border: "1px solid",
          borderColor: "error.light",
        }),
      },
    },
  },
  palette: {
    action: {
      active: "#000",
    },
    text: {
      secondary: "#161616",
      primary: "#161616",
    },
    secondary: {
      main: "#D3F1F8",
      contrastText: "#10AFD2",
    },
    background: {
      paper: "#FFF",
      default: "#F5F5F5",
    },
    divider: "#CFDBDE",
    // success: {
    //   main: "#000",
    // },
    primary: {
      main: "#10AED1",
      contrastText: "#fff",
    },
    grey: {
      "300": "#070707", //card
      "400": "#F5F5F5",
      "500": "#CFDBDE",
    },

    // text:{
    //   primary:"#fff"
    // }
  },

  typography: {
    fontFamily: "'Tajawal', sans-serif",
  },
  zIndex: {
    drawer: 1000,
  },

  // spacing: [0, 4, 8, 16, 32, 64],
});
