import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { AdminPermetion } from "constants/permeation";
import { RootState } from "core/store";
import { Admin, Permetion } from "core/types/rules";

// Define a type for the slice state
export interface AlertSetting {
  active?: boolean;
  severity: "error" | "warning" | "success" | "info";
  message: string;
}

interface SettingState {
  alert: AlertSetting;
  permetion?: Permetion;
  sideBarIsOpen: boolean;
}

// Define the initial state using that type
const initialState: SettingState = {
  alert: {
    active: false,
    severity: "success",
    message: "success",
  },
  sideBarIsOpen: false,
  permetion: AdminPermetion,
};

export const SettingSlice = createSlice({
  name: "Setting",
  initialState,
  reducers: {
    openAlert: (state, { payload }: PayloadAction<AlertSetting>) => {
      state.alert = { ...payload, active: true };
    },
    closeAlert: (state) => {
      state.alert.active = false;
    },
    setPermetion: (state, { payload }: PayloadAction<Permetion>) => {
      state.permetion = payload;
    },
    toggleSideBarIsOpen: (state) => {
      state.sideBarIsOpen = !state.sideBarIsOpen;
    },
  },
});

export const { openAlert, closeAlert, setPermetion, toggleSideBarIsOpen } =
  SettingSlice.actions;
export const SetRulesByType =
  (adminType: Admin): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    switch (adminType) {
      case "admin":
        dispatch(setPermetion(AdminPermetion));
        break;

      default:
        dispatch(setPermetion(AdminPermetion));

        break;
    }
  };

export default SettingSlice.reducer;
